import React, { useContext, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { LangContext } from "../context/LangContext"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const NotFoundPage = ({location}) => {
  const { lang } = useContext(LangContext)

  useEffect(() => {
    let navbarDesktop = document.querySelectorAll(".navbar.dark .nav-link")
    let darkLogo = document.querySelector(".navbar.dark .navbar-brand img.dark")
    let lightLogo = document.querySelector(".navbar.dark .navbar-brand img.light")
    let buttonMobileMenu = document.querySelectorAll(".navbar-toggler .icon-bar")

    darkLogo.style.display = `block`
    lightLogo.style.display = `none`

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#333"
    }

    for (let i = 0; i < buttonMobileMenu.length; i++) {
      buttonMobileMenu[i].style.backgroundColor = "#333"
    }

  }, [])

  return(
    <Layout location={location}>
      <SEO title="404: Not found"/>
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div className="not-found py-main d-flex flex-column align-items-center">
        <h1 className="mb-4 title" style={{fontSize:"4rem"}}>404</h1>
        <p className="mb-1 subtitle" style={{fontSize:"1.5rem"}}>
          {lang === "EN"
            ? "Looks like you've got lost..."
            : "Sepertinya anda tersesat..."}
        </p>
        <p className="text-muted">
          {lang === "EN"
            ? "The page you're looking for doesn't exists or has been moved."
            : "Halaman yang anda cari tidak dapat ditemukan atau sudah dipindahkan."}
        </p>
        <Link
          to="/"
          className="btn btn-link font-size-lg fx-underline btn-font"
          style={{fontWeight:"bold"}}
        >
          {lang === "EN" ? "Back to home" : "Kembali Ke Beranda"}
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
